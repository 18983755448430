import { createStore } from 'redux'
import { UpperFirstCase } from './string'

const initial = {
    profile: {},
    adminLogin: false,
    adminId: null,
    adminDisplayName: 'Anonymous',
    adminName: 'Anonymous',
    adminRole: 'Anonymous',
    adminRoleId: -1,
    adminPermission: [],
    adminPic:
        'https://static.estopolis.com/profile/user/user_profile_default.png',
    roles: {}
}

const reducer = (state = initial, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                ...{
                    adminLogin: true,
                    adminId: action.id,
                    adminName: action.fullname,
                    adminDisplayName: action.name,
                    adminRole: UpperFirstCase(action.role),
                    adminRoleId: action.roleId,
                    adminPic: action.pic,
                    adminPermission: action.permission,
                    profile: {
                        id: action.id,
                        name: action.name,
                        fullname: action.fullname,
                        role: UpperFirstCase(action.role),
                        picture:
                            action.pic ||
                            'https://static.estopolis.com/profile/user/user_profile_default.png',
                        bio: action.bio,
                        email: action.email
                    }
                }
            }
        case 'LOGOUT':
            return initial
        case 'ROLES':
            return { ...state, roles: action.roles }
        default:
            return state
    }
}

const makeStore = (initialState, options) => {
    return createStore(reducer, initialState)
}

export { makeStore }
