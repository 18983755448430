import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import { Provider } from 'react-redux'
import withRedux from 'next-redux-wrapper'
import NProgress from 'nprogress'
import * as Sentry from '@sentry/node'

import { makeStore } from '@/lib/redux'
import { checkAuthen, checkPermission } from '@/lib/authen'
import { getRouteConfig } from '@/lib/route'

import { redirectTo } from '../lib/route'
import '@/styles/global.scss'

Sentry.init({
    dsn:
        'https://29aec7051f574dbd9ab9367e92d0cab4@o374204.ingest.sentry.io/5191819',
    enabled: process.env.NODE_ENV === 'production',
})

class MyApp extends React.Component {
    static async getInitialProps({ Component, ctx }) {
        let pageProps = {}
        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx)
        }
        const route = await getRouteConfig(ctx.pathname)
        if (route) {
            let path = await checkAuthen({
                ctx: ctx,
                isRouteUnAuthOnly: route.unAuthOnly || false,
            })
            if (!path) {
                const { adminPermission } = ctx.store.getState()
                if (route.permissions) {
                    path = await checkPermission({
                        permittedPage: route.permissions,
                        userPermissions: adminPermission,
                        ctx,
                    })
                }
            }
            if (path) redirectTo(path, { res: ctx.res, status: 301 })
        }

        Router.events.on('routeChangeStart', (url) => {
            NProgress.start()
        })
        Router.events.on('routeChangeComplete', () => NProgress.done())
        Router.events.on('routeChangeError', () => NProgress.done())

        return { pageProps }
    }

    render() {
        const { Component, pageProps, store } = this.props

        // Workaround for https://github.com/zeit/next.js/issues/8592
        const { err } = this.props
        const modifiedPageProps = { ...pageProps, err }

        return (
            <Provider store={store}>
                <Component {...modifiedPageProps} />
            </Provider>
        )
    }
}

export default withRedux(makeStore)(MyApp)
